exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-erhebung-js": () => import("./../../../src/pages/leistungen/erhebung.js" /* webpackChunkName: "component---src-pages-leistungen-erhebung-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-leistungen-schwerpunkte-allgemeinmedizin-js": () => import("./../../../src/pages/leistungen/schwerpunkte/allgemeinmedizin.js" /* webpackChunkName: "component---src-pages-leistungen-schwerpunkte-allgemeinmedizin-js" */),
  "component---src-pages-leistungen-schwerpunkte-bewegungsapparat-js": () => import("./../../../src/pages/leistungen/schwerpunkte/bewegungsapparat.js" /* webpackChunkName: "component---src-pages-leistungen-schwerpunkte-bewegungsapparat-js" */),
  "component---src-pages-leistungen-schwerpunkte-covid-js": () => import("./../../../src/pages/leistungen/schwerpunkte/covid.js" /* webpackChunkName: "component---src-pages-leistungen-schwerpunkte-covid-js" */),
  "component---src-pages-leistungen-schwerpunkte-multimodale-schmerztherapie-js": () => import("./../../../src/pages/leistungen/schwerpunkte/multimodale-schmerztherapie.js" /* webpackChunkName: "component---src-pages-leistungen-schwerpunkte-multimodale-schmerztherapie-js" */),
  "component---src-pages-leistungen-schwerpunkte-sportmedizin-js": () => import("./../../../src/pages/leistungen/schwerpunkte/sportmedizin.js" /* webpackChunkName: "component---src-pages-leistungen-schwerpunkte-sportmedizin-js" */),
  "component---src-pages-leistungen-therapie-angebot-js": () => import("./../../../src/pages/leistungen/therapie-angebot.js" /* webpackChunkName: "component---src-pages-leistungen-therapie-angebot-js" */),
  "component---src-pages-praxis-einblicke-js": () => import("./../../../src/pages/praxis/einblicke.js" /* webpackChunkName: "component---src-pages-praxis-einblicke-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

